interface ChipItem {
  id: number,
  text: string,
  textColor?: string,
  chipColor?: string,
  prefix?: string,
}

interface OutgoingDTO extends ChipItem {
  disabled?: boolean,
  divider?: boolean,
  header?: string,
}

interface IncomingDTO {
  id: number,
  label: string,
  'label_color': string,
  'label_text_color': string,
}

const TagToAutocomplete = (
  {
    id, label, label_color: labelColor, label_text_color: labelTextColor,
  }: IncomingDTO,
): OutgoingDTO => ({
  id,
  text: label,
  textColor: labelTextColor,
  chipColor: labelColor,
});

export { TagToAutocomplete, IncomingDTO, OutgoingDTO };
