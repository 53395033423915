
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { AutocompleteItem } from '@/components/commonComponents/Chip.vue';
import { FetchResult } from 'apollo-link';
import { FetchPolicy } from 'apollo-client';
import { TagToAutocomplete } from '@/helpers/mappers/tags-autocomplete-mapper';
import Message from '@/components/mixins/Message.vue';
import EventBus from '@/event-bus';

// Lazy loading imports
const Chip = () => import(
  /* webpackChunkName: "chip" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Chip.vue'
);

export interface LabelItem {
  id: number,
  label: string,
  'label_color': string,
  'label_text_color': string,
}

export type LabelItems = Array<LabelItem>;

@Component({
  name: 'Tags',
  components: {
    Chip,
  },
})
export default class Tags extends Vue {
  @Prop({ default: () => [] })
  private readonly tags!: LabelItems;

  @Prop({ default: () => [] })
  private readonly addTagsArray!: Array<{ icon: string, text: string, typeId: string }>;

  @Prop()
  private readonly applyTagToDossier!: boolean;

  @Prop()
  private readonly tagCreateLabel!: boolean;

  @Prop()
  private readonly dossierId?: number;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly navigationSlug?: string;

  @Prop({
    default: () => ({
      updatable: false,
      updatableCustom: false,
      creatable: true,
      creatableCustom: true,
      removable: true,
    }),
  })
  private readonly permissions!: {
    updatable?: boolean,
    updatableCustom?: boolean,
    creatable?: boolean,
    creatableCustom?: boolean,
    removable?: boolean
  };

  protected selectableItems: Array<AutocompleteItem> = [];

  private loading = false;

  mounted(): void {
    if (this.dossierId) {
      this.fetchAutocompleteOptions();
    }
  }

  @Watch('loading')
  private emitLoading() {
    this.$emit('update:loading', this.loading);
  }

  private fetchAutocompleteOptions(fetchPolicy: FetchPolicy = 'cache-first'): void {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
    };

    this.loading = true;
    import('@/graphql/queries/dossier-type-tags-by-dossier-id')
      .then(({ default: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables,
      }))
      .then((response) => response.data.autocompleteTags.map(TagToAutocomplete))
      .then((tags: Array<AutocompleteItem>) => {
        this.selectableItems = tags;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private removeTagAction(id: number): void {
    this.loading = true;

    this.unlinkTag(id)
      .then(() => {
        EventBus.$emit('initializeForceRerender');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$emit('update');
      });
  }

  private addTagAction(id: string): void {
    this.loading = true;

    this.addTag(parseInt(id, 10))
      .then(() => {
        EventBus.$emit('initializeForceRerender');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$emit('update');
      });
  }

  private addCustomTagAction(value: string): void {
    this.loading = true;

    this.addNewTag(value)
      .then(() => {
        EventBus.$emit('initializeForceRerender');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$emit('update');
      });
  }

  private updateTagAction(fromId: number, toId: number): void {
    this.loading = true;

    this.addTag(toId)
      .then(() => this.unlinkTag(fromId))
      .then(() => {
        EventBus.$emit('initializeForceRerender');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$emit('update');
      });
  }

  private updateCustomTagAction(fromId: number, toValue: string): void {
    this.addNewTag(toValue)
      .then(() => this.unlinkTag(fromId))
      .then(() => {
        EventBus.$emit('initializeForceRerender');
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.$emit('update');
      });
  }

  private addTag(tagId: number): Promise<FetchResult> {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      tagId,
    };

    return new Promise((resolve, reject) => import('@/graphql/mutations/add-tag-to-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      }));
  }

  private addNewTag(value: string): Promise<FetchResult> {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      label: value,
    };

    return new Promise((resolve, reject) => import('@/graphql/mutations/add-new-tag-to-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      }));
  }

  private unlinkTag(tagId: number): Promise<FetchResult> {
    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      tagId,
    };

    return new Promise((resolve, reject) => import('@/graphql/mutations/unlink-tag-from-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      }));
  }

  private filterSelectableItems(list: Array<AutocompleteItem>): Array<AutocompleteItem> {
    return list.filter((item) => (
      !this.tags.map((tag) => (
        tag.id
      )).includes(item.id)
    ));
  }
}
